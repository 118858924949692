import { createRouter, createWebHistory } from "vue-router";
import { isPast, parseJSON } from "date-fns";
import store from "@/store/index.js";

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
  // },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  // {
  //   path: "/rooster",
  //   name: "Schedule",
  //   component: () => {
  //     if (store.state.userRoles.includes("Manager")) {
  //       return import(
  //         /* webpackChunkName: "submissionsManager" */ "@/views/management/schedule.vue"
  //       );
  //     } else {
  //       return import(
  //         /* webpackChunkName: "schedule" */ "@/views/common/schedule.vue"
  //       );
  //     }
  //   },
  // },
  {
    path: "/info",
    name: "Info",
    component: () => import(/* webpackChunkName: "info" */ "@/views/students/info.vue"),
  },
  {
    path: "/firstlogin",
    name: "Firstlogin",
    component: () => import(/* webpackChunkName: "firstlogin" */ "@/views/FirstLogin.vue"),
  },
  {
    path: "/passwordreset",
    name: "PasswordReset",
    component: () => import(/* webpackChunkName: "passwordreset" */ "@/views/PasswordReset.vue"),
  },
  {
    path: "/",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("user") && !isPast(parseJSON(JSON.parse(localStorage.getItem("user")).expiration))) {
        next({ name: "Dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import(/* webpackChunkName: "logout" */ "@/views/Logout.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/me",
    name: "Profile",
    component: () => import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import(/* webpackChunkName: "submissionsManager" */ "@/views/common/dashboard.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/opdrachten",
    name: "Assignments",
    component: function () {
      if (store.state.userRoles.includes("Teacher")) {
        return import(/* webpackChunkName: "teacherassignment" */ "@/views/common/assignments/index.vue");
      } else if (store.state.userRoles.includes("Manager")) {
        return import(/* webpackChunkName: "managerassignments" */ "@/views/management/assignments/index.vue");
      } else if (store.state.userRoles.includes("Student")) {
        return import(/* webpackChunkName: "assignments" */ "@/views/common/assignments/index.vue");
      } else if (store.state.userRoles.includes("Admin")) {
        return import(/* webpackChunkName: "assignments" */ "@/views/management/assignments/index.vue");
      }
      // return import(/* webpackChunkName: "assignments" */ '@/views/Assignments.vue');
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        //if (!store.state.userRoles.includes("Manager")) {
        //  next({ name: '401' });
        //} else {
        next();
        //}
      }
    },
  },
  {
    path: "/opdrachten/nieuw",
    name: "AssignmentCreate",
    component: () => import(/* webpackChunkName: "createAssignment" */ "@/views/management/assignments/create.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/opdrachten/:id",
    name: "Assignment",
    component: function () {
      if (store.state.userRoles.includes("Teacher")) {
        return import(/* webpackChunkName: "teacherassignment" */ "@/views/common/assignments/show.vue");
      } else if (store.state.userRoles.includes("Manager")) {
        return import(/* webpackChunkName: "managerassignments" */ "@/views/management/assignments/show.vue");
      } else if (store.state.userRoles.includes("Student")) {
        return import(/* webpackChunkName: "assignments" */ "@/views/common/assignments/show.vue");
      } else if (store.state.userRoles.includes("Admin")) {
        return import(/* webpackChunkName: "assignments" */ "@/views/management/assignments/show.vue");
      }
      // return import(/* webpackChunkName: "assignments" */ '@/views/Assignments.vue');
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        //if (!store.state.userRoles.includes("Manager")) {
        //  next({ name: '401' });
        //} else {
        next();
        //}
      }
    },
  },
  {
    path: "/klassen",
    name: "Classes",
    component: function () {
      if (store.state.userRoles.includes("Teacher")) {
        return import(/* webpackChunkName: "teacherassignment" */ "@/views/teachers/classes/index.vue");
      } else if (store.state.userRoles.includes("Manager")) {
        return import(/* webpackChunkName: "managerClassesIndex" */ "@/views/management/classes/index.vue");
      } else if (store.state.userRoles.includes("Student")) {
        return import(/* webpackChunkName: "assignments" */ "@/views/common/assignments/index.vue");
      } else if (store.state.userRoles.includes("Admin")) {
        return import(/* webpackChunkName: "assignments" */ "@/views/management/classes/index.vue");
      }
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager") && !store.state.userRoles.includes("Teacher")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/klassen/nieuw",
    name: "ClassCreate",
    component: () => import(/* webpackChunkName: "createClass" */ "@/views/management/classes/create.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/klassen/:id",
    name: "Class",
    component: function () {
      if (store.state.userRoles.includes("Teacher")) {
        return import(/* webpackChunkName: "teacherassignment" */ "@/views/teachers/classes/show.vue");
      } else if (store.state.userRoles.includes("Manager")) {
        return import(/* webpackChunkName: "managerClassesIndex" */ "@/views/management/classes/show.vue");
      }
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager") && !store.state.userRoles.includes("Teacher")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/downloadables",
    name: "Downloadables",
    component: function () {
      if (store.state.userRoles.includes("Teacher")) {
        return import(/* webpackChunkName: "teacherdownloadables" */ "@/views/About.vue");
      } else if (store.state.userRoles.includes("Manager")) {
        return import(/* webpackChunkName: "managerdownloadables" */ "@/views/management/downloadables/index.vue");
      } else if (store.state.userRoles.includes("Student")) {
        return import(/* webpackChunkName: "studentdownloadables" */ "@/views/common/assignments/index.vue");
      } else if (store.state.userRoles.includes("Admin")) {
        return import(/* webpackChunkName: "admindownloadables" */ "@/views/management/downloadables/index.vue");
      }
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("user"));
      console.log(store.state.userRoles);
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/downloadables/nieuw",
    name: "DownloadableCreate",
    component: () => import(/* webpackChunkName: "downloadableCreate" */ "@/views/management/downloadables/create.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/downloadables/:id",
    name: "DownloadableShow",
    component: () => import(/* webpackChunkName: "downloadableShow" */ "@/views/management/downloadables/show.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/opleidingen",
    name: "Educations",
    component: function () {
      if (store.state.userRoles.includes("Teacher")) {
        return import(/* webpackChunkName: "teacherassignment" */ "@/views/teachers/educations/index.vue");
      } else if (store.state.userRoles.includes("Manager")) {
        return import(/* webpackChunkName: "managerClassesIndex" */ "@/views/management/educations/index.vue");
      }
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager") && !store.state.userRoles.includes("Teacher")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/opleidingen/nieuw",
    name: "EducationCreate",
    component: () => import(/* webpackChunkName: "educations" */ "@/views/management/educations/create.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/opleidingen/:id",
    name: "Education",
    component: function () {
      if (store.state.userRoles.includes("Teacher")) {
        return import(/* webpackChunkName: "teacherassignment" */ "@/views/teachers/educations/show.vue");
      } else if (store.state.userRoles.includes("Manager")) {
        return import(/* webpackChunkName: "managerClassesIndex" */ "@/views/management/educations/show.vue");
      }
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager") && !store.state.userRoles.includes("Teacher")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/info",
    name: "Info",
    component: () => import(/* webpackChunkName: "info" */ "@/views/students/info.vue"),
  },
  {
    path: "/lessen",
    name: "Lessons",
    component: () => import(/* webpackChunkName: "lessons" */ "@/views/management/lessons/index.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/lessen/nieuw",
    name: "LessonCreate",
    component: () => import(/* webpackChunkName: "lessonCreate" */ "@/views/management/lessons/create.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/lessen/:id",
    name: "LessonShow",
    component: function () {
      if (store.state.userRoles.includes("Teacher")) {
        return import(/* webpackChunkName: "teacherassignment" */ "@/views/common/lessons/show.vue");
      } else if (store.state.userRoles.includes("Manager")) {
        return import(/* webpackChunkName: "managerassignments" */ "@/views/management/lessons/show.vue");
      } else if (store.state.userRoles.includes("Student")) {
        return import(/* webpackChunkName: "assignments" */ "@/views/common/lessons/show.vue");
      } else if (store.state.userRoles.includes("Admin")) {
        return import(/* webpackChunkName: "assignments" */ "@/views/management/lessons/show.vue");
      }
      // return import(/* webpackChunkName: "assignments" */ '@/views/Assignments.vue');
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        //if (!store.state.userRoles.includes("Manager")) {
        //  next({ name: '401' });
        //} else {
        next();
        //}
      }
    },
  },
  {
    path: "/managers",
    name: "Managers",
    component: () => import(/* webpackChunkName: "managers" */ "@/views/management/users/managers/index.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/managers/nieuw",
    name: "ManagerCreate",
    component: () => import(/* webpackChunkName: "managerCreate" */ "@/views/management/users/managers/create.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/managers/:id",
    name: "ManagerShow",
    component: () => import(/* webpackChunkName: "managerShow" */ "@/views/management/users/managers/show.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  // {
  //   path: "/vakken",
  //   name: "Subjects",
  //   component: function () {
  //     if (store.state.userRoles.includes("Teacher")) {
  //       return import(
  //         /* webpackChunkName: "teacherassignment" */ "@/views/teachers/subjects/index.vue"
  //       );
  //     } else if (store.state.userRoles.includes("Manager")) {
  //       return import(
  //         /* webpackChunkName: "managerassignments" */ "@/views/management/subjects/index.vue"
  //       );
  //     } else if (store.state.userRoles.includes("Student")) {
  //       return import(
  //         /* webpackChunkName: "assignments" */ "@/views/students/subjects/index.vue"
  //       );
  //     } else if (store.state.userRoles.includes("Admin")) {
  //       return import(
  //         /* webpackChunkName: "assignments" */ "@/views/management/subjects/index.vue"
  //       );
  //     }
  //     // return import(/* webpackChunkName: "assignments" */ '@/views/Assignments.vue');
  //   },
  //   beforeEnter: (to, from, next) => {
  //     if (!localStorage.getItem("user")) {
  //       next({ name: "Login" });
  //     } else {
  //       next();
  //     }
  //   },
  // },
  {
    path: "/vakken/nieuw",
    name: "SubjectCreate",
    component: () => import(/* webpackChunkName: "subjectCreate" */ "@/views/management/subjects/create.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/vakken/:id",
    name: "SubjectShow",
    component: function () {
      if (store.state.userRoles.includes("Teacher")) {
        return import(/* webpackChunkName: "teacherassignment" */ "@/views/teachers/subjects/show.vue");
      } else if (store.state.userRoles.includes("Manager")) {
        return import(/* webpackChunkName: "managerassignments" */ "@/views/management/subjects/show.vue");
      } else if (store.state.userRoles.includes("Student")) {
        return import(/* webpackChunkName: "assignments" */ "@/views/students/subjects/show.vue");
      } else if (store.state.userRoles.includes("Admin")) {
        return import(/* webpackChunkName: "assignments" */ "@/views/management/subjects/show.vue");
      }
      // return import(/* webpackChunkName: "assignments" */ '@/views/Assignments.vue');
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/inzendingen",
    name: "Submissions",
    component: () => {
      if (store.state.userRoles.includes("Manager")) {
        return import(/* webpackChunkName: "submissionsManager" */ "@/views/management/submissions/index.vue");
      } else if (store.state.userRoles.includes("Student")) {
        return import(/* webpackChunkName: "submissionsManager" */ "@/views/students/submissions/index.vue");
      } else if (store.state.userRoles.includes("Teacher")) {
        return import(/* webpackChunkName: "submissionsManager" */ "@/views/teachers/submissions/index.vue");
      } else if (store.state.userRoles.includes("Admin")) {
        return import(/* webpackChunkName: "submissionsManager" */ "@/views/management/submissions/index.vue");
      }
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/inzendingen/nieuw",
    name: "SubmissionCreate",
    component: () => import(/* webpackChunkName: "submissionCreate" */ "@/views/management/submissions/create.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/inzendingen/:id",
    name: "SubmissionShow",
    component: () => {
      if (store.state.userRoles.includes("Manager")) {
        return import(/* webpackChunkName: "submissionsManager" */ "@/views/management/submissions/show.vue");
      } else if (store.state.userRoles.includes("Student")) {
        return import(/* webpackChunkName: "submissionsManager" */ "@/views/students/submissions/show.vue");
      } else if (store.state.userRoles.includes("Teacher")) {
        return import(/* webpackChunkName: "submissionsManager" */ "@/views/teachers/submissions/show.vue");
      } else if (store.state.userRoles.includes("Admin")) {
        return import(/* webpackChunkName: "submissionsManager" */ "@/views/management/submissions/show.vue");
      }
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/studenten",
    name: "Students",
    component: () => import(/* webpackChunkName: "students" */ "@/views/management/users/students/index.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/studenten/nieuw",
    name: "StudentCreate",
    component: () => import(/* webpackChunkName: "studentCreate" */ "@/views/management/users/students/create.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/studenten/:id",
    name: "StudentShow",
    component: () => import(/* webpackChunkName: "studentShow" */ "@/views/management/users/students/show.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/docenten",
    name: "Teachers",
    component: () => import(/* webpackChunkName: "teachers" */ "@/views/management/users/teachers/index.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/docenten/nieuw",
    name: "TeacherCreate",
    component: () => import(/* webpackChunkName: "teacherCreate" */ "@/views/management/users/teachers/create.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/docenten/:id",
    name: "TeacherShow",
    component: () => import(/* webpackChunkName: "teacherShow" */ "@/views/management/users/teachers/show.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/videos",
    name: "Videos",
    component: () => {
      if (store.state.userRoles.includes("Manager")) {
        console.log(store.state.userRoles);
        return import(/* webpackChunkName: "videos" */ "@/views/management/videos/index.vue");
      } else if (store.state.userRoles.includes("Student")) {
        console.log(store.state.userRoles);

        return import(/* webpackChunkName: "videos" */ "@/views/students/video.vue");
      }
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/videos/nieuw",
    name: "VideoCreate",
    component: () => import(/* webpackChunkName: "videoCreate" */ "@/views/management/videos/create.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/videos/:id",
    name: "VideoShow",
    component: () => import(/* webpackChunkName: "videoShow" */ "@/views/management/videos/show.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ name: "Login" });
      } else {
        if (!store.state.userRoles.includes("Manager")) {
          next({ name: "401" });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/401",
    name: "401",
    component: () => import(/* webpackChunkName: "401" */ "@/views/401.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
