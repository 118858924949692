<template>
  <!-- <Header v-if="$route.name != 'Login'" /> -->
  <!-- <div v-if="$route.name != 'Login'" style="display:block;margin-bottom:60px;"></div> -->
  <!-- Bind a dynamic key to force re-rendering when user roles change -->
  <router-view />
  <!-- {{ $route.name }} -->
  <div v-if="$route.name != 'Login'" style="display: block; margin-bottom: 60px"></div>
</template>

<script>
// import Header from "@/components/common/Header.vue";

export default {
  components: {
    // Header,
  },
  beforeMount() {
    require("@/assets/scss/element-variables.scss");
  },
  updated() {
    this.updateHeight();
  },
  methods: {
    async updateHeight() {
      await this.$nextTick();
      resizeFunction();
    },
  },
};
window.onscroll = function () {
  scrollFunction();
};
window.onresize = function () {
  resizeFunction();
};

function resizeFunction() {
  var topSection = document.getElementById("topSection");
  var secondSection = document.getElementById("secondSection");
  // console.log("seccond", secondSection);
  if (topSection != null && secondSection != null) {
    secondSection.style.marginTop = (topSection.clientHeight - 1) + "px";
  }
  if (topSection != null) {
    topSection.style.marginTop = -(topSection.clientHeight - 1) + "px";
  }
}

function scrollFunction() {
  var topSection = document.getElementById("topSection");
  var textColOne = document.getElementById("textColOne");
  var textColTwo = document.getElementById("textColTwo");

  var scrolled = window.top.scrollY;
  if (textColOne != null && textColTwo != null && topSection != null) {
    topSection.style.marginBottom = -(scrolled / 2) + "";
    textColOne.style.opacity = 100 - scrolled + "%";
    textColTwo.style.opacity = 100 - scrolled + "%";
    topSection.style.opacity = 100 - scrolled / 5 + "%";
  }
}
</script>

<style lang="scss">
.topSection {
  position: fixed;
}

.backImg {
  vertical-align: -30%;
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
  height: 25px;
  margin-top: 5px;
}

.topSection,
.topSection {
  background: linear-gradient(315deg, #5c5c5c, #383838);
  color: white;
  border-radius: 0% 0% 0% 35px;
}

.topSection .header-text,
.link {
  color: white;
}

.secondSection {
  background-color: #5c5c5c;
}

.secondSection .header-text {
  z-index: 1;
}

.secondSection:before {
  content: "";
  position: absolute;
  border-radius: 0px 35px 0px 0px;
  width: 100%;
  height: 80px;
  background-color: white;
  top: 0px;
  left: 0;
}

body {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  font-size: 14px;
}

.text-center {
  text-align: center;
}

.slotRow {
  padding: 10px !important;
  -webkit-box-shadow: 0px 0px 12px 5px #01274C24;
  box-shadow: 0px 0px 12px 5px #01274C24;
  margin-bottom: 0%;
  border-radius: 3px;
}

@font-face {
  font-family: Poppins;
  src: url(assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins;
  src: url(assets/fonts/Poppins-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url(assets/fonts/Poppins-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url(assets/fonts/Poppins-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url(assets/fonts/Poppins-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url(assets/fonts/Poppins-Thin.ttf);
  font-weight: 100;
  font-style: normal;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: white;
  }

  a {
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.header-text {
  font-size: 24px;
  color: #373737;
  font-weight: 400;
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
}

.el-button--primary {
  border-radius: 6px !important;
  border: 0 solid #ff7579 !important;
}

.el-button--primary:hover {
  border: 0 solid #ff666c !important;
}

.el-date-editor {
  width: 100% !important;
}

.hide {
  display: none;
}

.page-header {
  padding: 15px !important;
  //min-height: 60px !important;
  //background: linear-gradient(315deg, #ffa3a6, #ec6669);
  // background-color: #ff7579;
  //border-radius: 0 0 0 25px;
  //color: #fff;
  text-align: center;
  align-items: baseline;
  //margin-bottom: 20px !important;
}

.page-header svg {
  font-size: 20px;
}

.page-header .title {
  font-size: 24px;
  font-weight: 400;
}

/** mgmt css */
.add-form {
  padding: 5px 10px;
  margin-bottom: 20px;
}

.entity-create-header {
  text-align: center;
  font-size: 18px;
  margin-bottom: 5%;
}

.delete-button {
  background-color: rgba(255, 50, 50);
  color: #fff;
  border-radius: 8px;
  border: none;
  padding: 12px;
  margin-bottom: 20px;
}

.delete-button.mini {
  padding: 7px 15px;
}

#floating-add-button {
  position: fixed;
  bottom: 75px;
  right: 15px;
  z-index: 999;
}

#floating-add-button svg {
  font-size: 14px;
  color: black;
  background-color: white;
  width: 30px;
  height: 30px;
  padding: 10px;
  border-radius: 100%;
  box-shadow: 0 5px 10px 1px rgb(184, 184, 184);
}

.error-message {
  color: white;
  padding: 7px 15px;
  background-color: rgb(255, 138, 141);
  border: 4px solid rgba(235, 0, 0, 1);
  border-radius: 15px;
}

.el-table__body {
  border-spacing: 0px 6px;
  table-layout: auto !important;
  padding: 0 20px;
}

/** for el-table rows */
.row-style {
  cursor: pointer;
  box-shadow: 0 5px 10px 1px rgb(184, 184, 184);
  border-radius: 3px;
}
</style>
