import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import schedule from "@/store/schedule";
import classes from "@/store/classes";

// Define a function to return the default state.
const getDefaultState = () => ({
  userRoles: [],
  authToken: "",
  userId: "",
});

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.localStorage, // Persist state to localStorage
    }),
  ],
  state: getDefaultState(),
  getters: {
    getUserRoles: (state) => state.userRoles,
    getAuthToken: (state) => state.authToken,
    getUserId: (state) => state.userId,
  },
  mutations: {
    setUserRoles(state, payload) {
      state.userRoles = payload;
    },
    setAuthToken(state, payload) {
      state.authToken = payload;
    },
    setUserId(state, payload) {
      state.userId = payload;
    },
    // Add a reset mutation that restores the default state.
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    storeUserRoles({ commit }, payload) {
      commit("setUserRoles", payload);
    },
    storeAuthToken({ commit }, payload) {
      commit("setAuthToken", payload);
    },
    storeUserId({ commit }, payload) {
      commit("setUserId", payload);
    },
    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
  modules: {
    schedule: schedule,
    classes: classes,
  },
});
